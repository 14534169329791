import React, { useState } from "react";
import DialogCard from "../../reusable/DialogCard";
import classes from "./EditEmployee.module.css";
import { createEmployee } from "../../../utils/ApiFunctions";

function AddEmployee({ open, handleClose, setEmployees }) {
  const [form, setForm] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleWheel = (e) => {
    e.target.blur(); // Remove focus to prevent value change
  };
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    // Allow only numeric characters
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  
  const handleSaveEmployee = async () => {
    setIsSaving(true);
    try {
      const resp = await createEmployee(form);
      if (resp) {
        setEmployees((prev) => [...prev, resp.data.data]);
        setForm({});
        handleClose();
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className={classes.dialog}>
      <DialogCard
        open={open}
        handleClose={handleClose}
        title={"New Employee"}
        content={
          <>
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Name
            </label>
            <input
              className={`${classes.control}`}
              type="text"
              placeholder="Enter name"
              id="custominput"
              name="name"
              value={form.name || ""}
              onChange={handleChange}
              autoComplete="off"
            />
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Phone no
            </label>
            <input
              className={`${classes.control}`}
              type="text"
              placeholder="Enter number"
              id="custominput"
              name="phoneNo"
              value={form.phoneNo || ""}
              onChange={handleChange}
              autoComplete="off"
              onWheel={handleWheel}
              onKeyPress={handleKeyPress}
            />
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Address
            </label>
            <input
              className={`${classes.control}`}
              type="text"
              placeholder="Enter address"
              id="custominput"
              name="address"
              value={form.address || ""}
              onChange={handleChange}
              autoComplete="off"
            />
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Email
            </label>
            <input
              className={`${classes.control}`}
              type="text"
              placeholder="Enter email"
              id="custominput"
              name="email"
              value={form.email || ""}
              onChange={handleChange}
              autoComplete="off"
            />
            <label className={`${classes.labell}`}>Age</label>
            <input
              className={`${classes.control} ${classes.lastcontrol}`}
              type="number"
              placeholder="Enter age"
              id="custominput"
              name="age"
              value={form.age || ""}
              onChange={handleChange}
              autoComplete="off"
              onWheel={handleWheel}
            />
          </>
        }
        btnOne={"Save"}
        btnTwo={"Cancel"}
        btnOneAction={handleSaveEmployee}
        btnOneDisabled={isSaving}
      />
    </div>
  );
}

export default AddEmployee;
