import AdminHeader from "./components/admin/AdminHeader";
import CreateEvent from "./components/admin/events comps/CreateEvent";
import Header from "./components/header comp/Header";
import Homepage from "./components/homepage comps/Homepage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ViewEvent from "./components/admin/events comps/ViewEvent";
import EditEvent from "./components/admin/events comps/EditEvent";
import EmployeeHeader from "./components/employee/EmployeeHeader";
import ManagerHeader from "./components/manager/ManagerHeader";
import ManagerViewDetails from "./components/manager/ManagerViewDetails";
import AssignasOptions from "./components/admin/events comps/AssignasOptions";
import MyCalendar from "./components/admin/calendar comps/MyCalendar";
import Employee from "./components/admin/employee comps/Employee";
import AllEvents from "./components/admin/events comps/AllEvents";
import UpcomingEvents from "./components/admin/events comps/UpcomingEvents";
import Finished from "./components/admin/events comps/Finished";
import Cancelled from "./components/admin/events comps/Cancelled";
import CustomEventHeader from "./components/reusable/CustomEventHeader";
import "./App.css";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/options" element={<AssignasOptions />} />
        <Route path="/create-event" element={<CreateEvent />} />
        <Route path="/employee" element={<EmployeeHeader />} />
        <Route path="/manager" element={<ManagerHeader />} />
        <Route path="/view-details/:id" element={<ManagerViewDetails />} />
        <Route path="/editevent/:id" element={<EditEvent />} />
        <Route path="/admin" element={<AdminHeader />}>
          <Route path="eventheader" element={<CustomEventHeader />}>
            <Route path="all" element={<AllEvents />} />
            <Route path="upcoming" element={<UpcomingEvents />} />
            <Route path="finished" element={<Finished />} />
            <Route path="cancelled" element={<Cancelled />} />
          </Route>
        </Route>
        <Route path="/calendar" element={<MyCalendar />} />
        <Route path="/viewevent/:id" element={<ViewEvent />} />
        <Route path="/adminemp" element={<Employee />} />
      </Routes>
    </Router>
  );
}

export default App;
