import React, { useEffect, useState } from "react";
import CustomTable from "../../reusable/CustomTable";
import EmployeeOptions from "./EmployeeOptions";
import classes from "./Employee.module.css";
import AddEmployee from "./AddEmployee";
import Header from "../Header";
import { getEmployees } from "../../../utils/ApiFunctions";
import Loader from "../../reusable/Loader";
import NoData from "../../reusable/NoData";
import MyPagination from "../../reusable/MyPagination";

const columns = [
  {
    Header: "#",
    accessor: "frontendId",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Unique Code",
    accessor: "code",
  },
  {
    Header: "Phone No.",
    accessor: "phoneNo",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "",
    accessor: "",
  },
];
function Employee(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [employees, setEmployees] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [page,setPage]=useState(1);
  const [totalPages,setTotalPages]=useState("");
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    getEmps();
  };

  const getEmps = async () => {
    const resp = await getEmployees();
    if (resp) {
      const tempEmployees = resp.data.employees.map((employee, index) => ({
        ...employee,
        frontendId: index + 1, // Generate auto-incrementing ID based on page and index
      }));
      setEmployees(tempEmployees);
      setTotalPages(resp.totalPages);
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    getEmps();
  }, []);

  return (
    <>
      <Header activeTab={"employee"} />
      {loading && <Loader />}
      {!loading && !employees[0] && (
        <NoData
          title={"No Employees added"}
          subtitle={"Please add employees!"}
        />
      )}
      {!loading && employees[0] && (
        <div className={classes.container}>
          <CustomTable
            columns={columns}
            data={employees}
            responsive={classes.stickyHeader}
            OptionsComponent={EmployeeOptions}
          />
          <button className={classes.btn} onClick={handleOpen}>
            Add New Employee
          </button>
          <AddEmployee
            open={dialogOpen}
            handleClose={handleClose}
            setEmployees={setEmployees}
          />
          {/* <MyPagination page={page} setPage={setPage} totalPages={totalPages}/> */}
        </div>
      )}
      {!loading && !employees[0] && (
        <div className={classes.container}>
          <button className={classes.btnabsent} onClick={handleOpen}>
            Add New Employee
          </button>
          <AddEmployee
            open={dialogOpen}
            handleClose={handleClose}
            setEmployees={setEmployees}
          />
        </div>
      )}
    </>
  );
}

export default Employee;
