import React, { useEffect, useState } from "react";
import backicon from "../../../../images/back.svg";
import calendaricon from "../../../../images/calendaricon.svg";
import timeicon from "../../../../images/timeicon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom";
import classes from "./LocalEventDetails.module.css";
import { useSelector } from "react-redux";
import { getCustomEvents, getOneEvent, getregisterations } from "../../../../utils/ApiFunctions";
import { Tab, Tabs, Box } from "@mui/material";
import LocalEventCard from "./LocalEventCard";
import Loader from "../../../reusable/Loader";
import { styled } from "@mui/material/styles";
import CustomTable from "../../../reusable/CustomTable";
import EmployeeOptions from "../EmployeeOptions";
import exportToExcel from "../../../reusable/exportToExcel";
import AddEmployeePopup from "../AddEmployeePopup";


export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Employee",
    accessor: "name",
  },
  {
    Header: "",
    accessor: "RoleIndicator",
  },
  {
    Header: "Court",
    accessor: "court",
  },
  {
    Header: "Transportation",
    accessor: "transportationStatus",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "",
  },
];
const LocalEventDetails = () => {
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([{}]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState([]);
  const [daytime, setDayTime] = useState("");
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filteredCourt, setFilteredCourt] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsData = [
    {
      id: 0,
      title: "Gold 1",
    },
    {
      id: 1,
      title: "Gold 2",
    },
    {
      id: 2,
      title: "007",
    },
    {
      id: 3,
      title: "747",
    },
    {
      id: 4,
      title: "Double",
    },
    {
      id: 5,
      title: "Add1",
    },
    {
      id: 6,
      title: "Add2",
    },
  ];

  const [lead, setLead] = useState("");
  const to = useSelector((state) => state.link.to);
  const [localCourts, setLocalCourts] = useState([]);
  const getRegs = async () => {
    setLoading(true);
    const resp = await getregisterations(id);
    if (resp) {
      const temp = resp?.data?.registeredEmployees?.map((reg, index) => {
        let roleIndicator = "";
        if (reg.event?.techManager === reg.employee?.name)
          roleIndicator += "⚙️";
        if (reg.event?.eventManager === reg.employee?.name)
          roleIndicator += "👑";

        return {
          _id: reg._id,
          id: index + 1,
          name: reg.employee.name,
          shortLead: reg?.event?.lead,
          employeeID: reg.employee._id,
          address: reg.employee.address,
          RoleIndicator: roleIndicator ? roleIndicator : "",
          phoneNo: reg?.employee?.phoneNo,
          email: reg?.employee?.email,
          court: reg?.court?.name || "NA",
          transportation: reg?.transportation ? "Requested" : "Refused",
          transportationStatus:
            reg?.transportationStatus !== undefined
              ? reg.transportationStatus
                ? "Approved"
                : "Declined"
              : reg?.transportation
                ? "Requested"
                : "-",
          status:
            reg.registrationStatus === true
              ? "Approved"
              : reg.registrationStatus === false
                ? "Declined"
                : "Pending",
          // courtData: reg?.event?.court,
          courtData: localCourts,
          eventid: id,
          techManager: reg.event?.techManager || null,
          eventManager: reg.event?.eventManager || null,
        };
      });
      setData(temp);
      setLoading(false);
    }
    setLoading(false);
  };

  const getEventDetails = async () => {
    setLoading(true);
    const resp = await getOneEvent(id);
    if (resp) {
      setEventDetails(resp.data);
      setLead(resp?.data?.lead);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    // getEventDetails();
    
    getRegs();
  }, [update,localCourts]);

  const handleEmployeeDownload = () => {
    if (data) {
      const filteredData = data
        .filter(
          (item) =>
            item.transportationStatus == "Approved" &&
            item.status === "Approved"
        ) // Filter rows where transportation is "Requested"
        .map(
          ({
            _id,
            RoleIndicator,
            courtData,
            eventid,
            employeeID,
            transportation,
            status,
            ...rest
          }) => rest
        ); // Exclude the some fields from each object
      if (filteredData.length > 0) {
        exportToExcel(filteredData, "employeeTransportation.xlsx");
      } else {
        alert("No approved transportation data available to export");
      }
    }
  };

  // const getEventDetails = async () => {
  //   setLoading(true);
  //   const resp = await getCustomEvents("Local");
  //   if (resp) {
  //     // console.log("event format data: ", resp);
  //     setEventDetails(resp.data);
  //     // setLead(resp?.data?.lead);
  //     setLoading(false);
  //   }
  // };
  // const getEventDetails = async () => {
  //   const resp = await getOneEvent(id);
  //   if (resp) {
  //     console.log(resp);

  //     setEventDetails(resp.data);
  //     setLead(resp?.data?.lead);
  //   }
  // };

  useEffect(() => {
    const fetchEventDetails = async () => {
      setLoading(true);
      const resp = await getOneEvent(id);
      if (resp) {
        const eventData = resp.data.data;
        setEventDetails(eventData);
        setLead(eventData.lead);
        filterCourts(eventData.groups);
        setDayTime({
          day: moment(eventData.date).format("dddd"),
          monthYear: moment(eventData.date).format("DD MMMM YYYY"),
          time: `${moment(eventData.fromTime).format("HH:mm")}-${moment(
            eventData.toTime
          ).format("HH:mm")}`,
        });
        setLoading(false);
      }
    };
    fetchEventDetails();
  }, [id]);

  // Filterd Courts
  // const filterCourts = (groups) => {
  //   if (!groups || !Array.isArray(groups)) {
  //     console.error("Invalid or undefined groups", groups);
  //     return;
  //   }
  //   const availableCourts = [];
  //   groups.forEach((group) => {
  //     group.timeSlots.forEach((slot) => {
  //       if (!availableCourts.includes(slot.court)) {
  //         availableCourts.push(slot.court);
  //       }
  //     });
  //   });
  //   const courtData = tabsData.filter((tab) =>
  //     availableCourts.includes(tab.title)
  //   );
  //   setFilteredCourt(courtData);
  // };

  // Filter the available courts from timeSlots
  const filterCourts = (groups) => {
    if (!groups || !Array.isArray(groups)) {
      console.error("Invalid or undefined groups", groups);
      return;
    }

    const availableCourts = [];

    groups.forEach((group) => {
      // Check if timeSlots exists and is an array
      if (group.timeSlots && Array.isArray(group.timeSlots)) {
        group.timeSlots.forEach((slot) => {
          if (slot.court && !availableCourts.includes(slot.court)) {
            availableCourts.push(slot.court);
          }
        });
      }
    });

    // Filter the available courts from the predefined tabsData
    const courtData = tabsData.filter((tab) =>
      availableCourts.includes(tab.title)
    );
    setFilteredCourt(courtData);
    setLocalCourts(courtData.map(item => ({
      name : item.title
    })));
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontFamily: "Poppins, sans-serif",
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.75)",
      "&.Mui-selected": {
        color: "#DB545D",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#DB545D",
      },
    })
  );

  // const data = [
  //   {
  //     eventName: "NewEvent",
  //     eventFormat: "Local",
  //     status: "Paid",
  //     date: "2023-08-03T00:00:00.000Z",
  //     time: [
  //       {
  //         id: 1,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 2,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 3,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //     ],
  //     gender: "Mix",
  //     ageGroup: ["Mix"],
  //     court: "007",
  //     payment: "Online",
  //     amount: 500,
  //     food: [
  //       "burger",
  //       "pizza",
  //       "tapas",
  //       "snacks",
  //       "beer",
  //       "popcorn",
  //       "salads",
  //       "total combo",
  //     ],
  //     contactPerson: "Jonh Doe",
  //     contactNumber: 9876543210,
  //     contactEmail: "johndoe@gmail.com",
  //   },
  //   {
  //     eventName: "NewEvent",
  //     eventFormat: "Local",
  //     status: "Paid",
  //     date: "2023-08-03T00:00:00.000Z",
  //     time: [
  //       {
  //         id: 1,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 2,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //       {
  //         id: 3,
  //         fromTime: "2023-08-03T07:00:00.000Z",
  //         toTime: "2023-08-03T13:00:00.000Z",
  //       },
  //     ],
  //     gender: "Mix",
  //     ageGroup: ["Mix"],
  //     court: "007",
  //     payment: "Online",
  //     amount: 500,
  //     food: [
  //       "burger",
  //       "pizza",
  //       "tapas",
  //       "snacks",
  //       "beer",
  //       "popcorn",
  //       "salads",
  //       "total combo",
  //     ],
  //     contactPerson: "Jonh Doe",
  //     contactNumber: 9876543210,
  //     contactEmail: "johndoe@gmail.com",
  //   },
  // ];

  const remark = eventDetails?.managerRemark;

  

  // useEffect(() => {
  //   setDayTime((prev) => ({
  //     day: moment(eventDetails?.date).format("dddd"),
  //     monthYear: moment(eventDetails?.date).format("DD MMMM YYYY"),
  //     time: `${moment(eventDetails?.fromTime).format("HH:mm")}-${moment(
  //       eventDetails?.toTime
  //     ).format("HH:mm")}`,
  //   }));
  // }, []);

  // useEffect(() => {
  //   setDayTime({
  //     day: moment(eventDetails?.date).format("dddd"),
  //     monthYear: moment(eventDetails?.date).format("DD MMMM YYYY"),
  //     time: `${moment(eventDetails?.fromTime).format("HH:mm")}-${moment(
  //       eventDetails?.toTime
  //     ).format("HH:mm")}`,
  //   });
  // }, [eventDetails]);

  // useEffect(() => {
  //   getEventDetails();
  //   // getRegs();
  // }, [id]);
  return (
    <>
      {loading && <Loader />}
      {!loading && eventDetails && (
        <div>
          <div className={classes.titlecontainer}>
            <div className={classes.detailscontainer}>
              <div onClick={() => navigate(-1)}>
                <div className={classes.back}>
                  <img src={backicon} alt="" />
                </div>
              </div>
              <div className={classes.eventdetailscontainer}>
                <div>
                  <h2 className={classes.title}>{eventDetails.eventName}</h2>
                  {/* <h2 className={classes.title}>{item?.eventName}</h2> */}
                  <div
                    className={`${classes.detailscontainer} ${classes.minicontainer}`}
                  >
                    <p>
                      <img
                        src={calendaricon}
                        alt=""
                        className={classes.iconstop}
                      />
                      {daytime.monthYear}
                    </p>
                    <p>
                      <img src={timeicon} alt="" className={classes.iconstop} />
                      {daytime.time}
                    </p>
                    <p>
                      <img
                        src={calendaricon}
                        alt=""
                        className={classes.iconstop}
                      />
                      {daytime.day}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className={classes.paymentstatus}>
              <div>Pending</div>
              {/* {item?.paymentStatus ? item?.paymentStatus : <div>Pending</div>} */}
            </p>
          </div>
          <div className={classes.titlecontainer}>
            <div className={classes.remarkWrapper}>
              <h3 className={classes.remarkTitle}>Feedback</h3>
              <p className={classes.remarkDisc}>{remark ? remark : "-"}</p>
            </div>
          </div>
          <Box sx={{ width: "100%", padding: "2rem 2.4rem", display: "flex" }}>
            <div style={{ width: "50%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  textColor="#DB545D"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "#DB545D",
                    },
                  }}
                >
                  {filteredCourt.map((tab, index) => (
                    <StyledTab
                      key={index}
                      label={tab.title}
                      {...a11yProps(index)}
                      sx={{ fontSize: 16, fontWeight: 600 }}
                    />
                  ))}
                </Tabs>
              </Box>
              <div>
                {filteredCourt.map((tab, index) => (
                  <CustomTabPanel value={value} index={index} key={index}>
                    <LocalEventCard
                      court={tab.title}
                      data={eventDetails && eventDetails.groups && eventDetails.groups.filter((group) =>
                        group.timeSlots.some((slot) => slot.court === tab.title)
                      )}
                    />
                  </CustomTabPanel>
                ))}
              </div>
            </div>
            <div className={classes.employeetablecontainer} style={{ width: "50%" }}>
              <CustomTable
                responsive={"viewEmployeeTabletTable"}
                columns={columns}
                data={data ? data : null}
                OptionsComponent={EmployeeOptions}
                status={true}
                update={update}
                setUpdate={setUpdate}
              />
              {(!eventDetails?.data?.eventEnded ||
                eventDetails.data.eventEnded === undefined) && (
                  <button className={classes.newempbtn} onClick={handleOpen}>
                    Add Employee
                  </button>
                )}
              {(!eventDetails?.data?.eventEnded ||
                eventDetails.data.eventEnded === undefined) &&
                data &&
                data.length > 0 && (
                  <button
                    className={classes.newempbtn}
                    onClick={handleEmployeeDownload}
                  >
                    Download
                  </button>
                )}
            </div>
          </Box>
        </div>

      )}
      <AddEmployeePopup
        open={dialogOpen}
        handleClose={handleClose}
        eventid={id}
        courtData={localCourts}
        update={update}
        setUpdate={setUpdate}
      />
    </>
  );
};

export default LocalEventDetails;
