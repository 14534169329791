import React, { useState } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";
import classes from "./CustomEventHeader.module.css";

function CustomEventHeader(props) {
  const [activeLinks, setActiveLinks] = useState("all");
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <>
      <div className={classes.linkcontainer}>
      <Link
          to={'./all'}
          className={` ${currentPath.includes('/admin/eventheader/all') ? classes.active : ""} ${classes.links
            }`}
          onClick={() => setActiveLinks((prev) => "all")}
        >
          All
        </Link>
        <Link
          to={'./upcoming'}
          className={`${classes.links} ${currentPath.includes('/admin/eventheader/upcoming') ? classes.active : ""
            }`}
          onClick={() => setActiveLinks((prev) => "upcoming")}
        >
          Upcoming
        </Link>
        <Link
          to={'./finished'}
          className={`${classes.links} ${currentPath.includes('/admin/eventheader/finished') ? classes.active : ""
            }`}
          onClick={() => setActiveLinks((prev) => "finished")}
        >
          Finished
        </Link>
        <Link
          to={'./cancelled'}
          className={`${classes.links} ${currentPath.includes('/admin/eventheader/cancelled') ? classes.active : ""
            }`}
          onClick={() => setActiveLinks((prev) => "cancelled")}
        >
          Cancelled
        </Link>
        
      </div>
      {/* {activeLinks==="all"&& <AllEvents/>}
      {activeLinks==="upcoming"&&<UpcomingEvents/>}
      {activeLinks==="finished"&& <Finished/>} */}
      <Outlet />
    </>
  );
}

export default CustomEventHeader;