import React, { useEffect, useRef, useState } from "react";
import classes from "./ViewOption.module.css";
import eyeicon from "../../images/eyeicon.svg";
import pencilicon from "../../images/edit-icon.svg"
import { useNavigate } from "react-router-dom";

function ViewOption({id}) {
  const [optionsOpen,setOptionsOpen]=useState(true);
  const handleBackdropClick = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setOptionsOpen(false);
    }
  };
  const boxRef = useRef(null);
  useEffect(() => {
    
    document.addEventListener('click', handleBackdropClick, true);
    return () => {
      document.removeEventListener('click', handleBackdropClick, true);
    
  }
  }, []);
  const navigate=useNavigate();
  return (
    optionsOpen&&<div className={classes.boxcontainer} ref={boxRef}>
      <div className={classes.box}>
        <div className={classes.btngrp}>
          <span className={classes.btn} onClick={()=>{navigate(`/view-details/${id}`)}}>
            <img
              src={eyeicon}
              alt="View details icon"
              className={classes.icon}
            />
            <p className={classes.two} >View Details</p>
          </span>
          <span className={classes.btn} onClick={()=>{navigate(`/editevent/${id}`)}}>
            <img
              src={pencilicon}
              alt="Edi icon"
              className={classes.icon}
            />
            <p className={classes.two} >Edit</p>
          </span>
        </div>
      </div>
      
    </div>
  );
}

export default ViewOption;