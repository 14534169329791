import React from 'react';
import classes from './CustomRadioSelect.module.css';

function CustomRadioSelect({img,values,setValue,styleclass,otherclass, rotateRight}) {
    const handleClick=(e)=>{
        setValue(prev=>values);
    }

    return (
        <>
        <button className={`${classes.btn} ${classes[styleclass]} ${classes[otherclass]}`} onClick={handleClick}>
           {img&& <img src={img} alt="" className={`${classes.img} ${rotateRight && 'rotateRight'}`}/>}
            {values}
            </button>
        </>
    );
}

export default CustomRadioSelect;