import React, { useEffect, useState } from "react";
import backicon from "../../../../images/back.svg";
import calendaricon from "../../../../images/calendaricon.svg";
import timeicon from "../../../../images/timeicon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom";
import classes from "./LocalEventDetails.module.css";
import { useSelector } from "react-redux";
import { getOneEvent } from "../../../../utils/ApiFunctions";
import { Tab, Tabs, Box } from "@mui/material";

const LocalEventCard = (props) => {
  const { data } = props;
  if (!Array.isArray(data) && data && data.length == 0) {
    return <p>No data available</p>;
  }
  return (
    <div className={classes.adminMainContainer}>
      {data.map((item, index) => {
        const isSingleItem = data.length === 1;
        const isMobile = window.innerWidth < 768;
        return (
          <div className={classes.widthfifty}>
            <div className={classes.container}>
              <div
                className={classes.moredetailscontainer}
                // style={{
                //   width: isSingleItem ? (isMobile ? "100%" : "75%") : "",
                // }}
              >
                <div className={classes.header}>
                  <div className={classes.signed}>
                    <p>{item?.groupName}</p>
                  </div>
                  <div className={classes.provided}>
                    {item?.food.length !== 0 ? (
                      <p>Food provided</p>
                    ) : (
                      <p>Food Not Provided</p>
                    )}
                  </div>
                </div>
                <div className={classes.extradetailscontainer}>
                  <div className={classes.assigned}>
                    <p className={classes.assignedvalue}>
                      Assigned to {item?.contactPerson}
                    </p>
                  </div>
                  <div className={classes.participantpayment}>
                    <div className={classes.participant}>
                      <p className={classes.titlelike}>
                        Participant Requirements
                      </p>
                      <p className={classes.valuelike}>
                        {item?.ageGroup.join(",")}
                      </p>
                    </div>
                    <div>
                      <p className={classes.titlelike}>No. of participants</p>
                      <p className={classes.valuelike}>
                        {item?.noOfParticipants
                          ? `${item?.noOfParticipants}`
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <p className={classes.titlelike}>Amount</p>
                      <p className={classes.valuelike}>
                        {item?.amount ? `${item?.amount}` : "-"}
                      </p>
                    </div>
                    <div>
                      <p className={classes.titlelike}>Payment</p>
                      <p className={classes.valuelike}>
                        {item?.payment ? item?.payment : "-"}
                      </p>
                    </div>
                    <div className={classes.assignedetails}>
                      <p className={classes.titlelike}>Contact No.</p>
                      <p className={classes.valuelike}>
                        {item?.contactNumber ? item?.contactNumber : "-"}
                      </p>
                    </div>
                    <div className={classes.assignedetails}>
                      <p className={classes.titlelike}>Contact Email ID</p>
                      <p className={classes.valuelike}>
                        {item?.contactEmail ? item?.contactEmail : "-"}
                      </p>
                    </div>
                  </div>
                  <div className={classes.foodcontainer}>
                    <ul>
                      {item?.food.map((f) => (
                        <li>{f}</li>
                      ))}
                    </ul>
                  </div>
                  <div className={classes.remarkscontainer}>
                    <p className={classes.titlelike}>Remarks</p>
                    <p className={classes.valuelike}>
                      {item?.adminRemark ? `${item?.adminRemark}` : "-"}
                    </p>
                  </div>
                  {/* <Box className={classes.endContainer}>
                    <div
                      className={`${classes.titlelike} ${classes.endContainerDiv}`}
                      sx={{}}
                    >
                      {moment(item?.time?.fromTime).format("HH:mm")}-
                      {moment(item?.time?.toTime).format("HH:mm")}
                    </div>
                  </Box> */}
                  <Box
                    display="flex"
                    justifyContent={isSingleItem ? "center" : "space-between"}
                    alignItems="center"
                    flexWrap="nowrap"
                    sx={{ width: "100%" }}
                  >
                    {item.timeSlots.map((slot, slotIndex) => (
                      <Box
                        className={classes.endContainer}
                        key={slotIndex}
                        display="flex"
                        alignItems="center"
                        justifyContent="center" 
                        sx={{
                          position: "relative",
                          textAlign: "center",
                          flex: 1,
                          ...(slotIndex > 0 && {
                            borderLeft: "1px solid #0000000d",
                            paddingLeft: "10px",
                          }),
                        }}
                      >
                        <div
                          className={`${classes.titlelike} ${classes.endContainerDiv}`}
                        >
                          {moment(slot.time.fromTime, "HH:mm").format("HH:mm")}{" "}
                          -{moment(slot.time.toTime, "HH:mm").format("HH:mm")}
                        </div>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LocalEventCard;
