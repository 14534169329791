import React, { useEffect, useState } from "react";
import classes from "./ViewEvent.module.css";
import ViewDetails from "../../reusable/ViewDetails";
import CustomTable from "../../reusable/CustomTable";
import EmployeeOptions from "./EmployeeOptions";
import AddEmployeePopup from "./AddEmployeePopup";
import { useParams } from "react-router-dom";
import { getOneEvent, getregisterations } from "../../../utils/ApiFunctions";
import { useSelector } from "react-redux";
import Loader from "../../reusable/Loader";
import exportToExcel from "../../reusable/exportToExcel";
import LocalEventDetails from "./LocalEventDetails/LocalEventDetails";

// const employees = [
//     {
//       id: 1,
//       name: "Leslie Alexander",
//       court: "NA",
//       transportation: "Requested",
//       status: "Pending"
//     },
//     {
//       id: 2,
//       name: "Esther Howard",
//       court: "NA",
//       transportation: "Refused",
//       status: "Pending"
//     },
//     {
//       id: 3,
//       name: "Floyd Miles",
//       court: "NA",
//       transportation: "Refused",
//       status: "Pending"
//     },
//     {
//       id: 4,
//       name: "Darlene Robertson",
//       court: "NA",
//       transportation: "Refused",
//       status: "Pending"
//     },
//     {
//       id: 5,
//       name: "Miles Morales",
//       court: "NA",
//       transportation: "Requested",
//       status: "Approved"
//     },
//     {
//       id: 6,
//       name: "Jenny Wilson",
//       court: "NA",
//       transportation: "Requested",
//       status: "Declined"
//     }
//   ];
const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Employee",
    accessor: "name",
  },
  {
    Header: "",
    accessor: "RoleIndicator",
  },
  {
    Header: "Court",
    accessor: "court",
  },
  {
    Header: "Transportation",
    accessor: "transportationStatus",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "",
  },
];

function ViewEvent(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const [update, setUpdate] = useState(false);
  const [lead, setLead] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([{}]);
  const to = useSelector((state) => state.link.to);
  const { id } = useParams();

  const getEventDetails = async () => {
    setLoading(true);
    const resp = await getOneEvent(id);
    if (resp) {
      setEventDetails(resp.data);
      setLead(resp?.data?.lead);
    }
    setLoading(false);
  };

  // const getRegs = async () => {
  //   const resp = await getregisterations(id);
  //   if (resp) {
  //     const temp = resp?.data?.registeredEmployees?.map((reg,index) => ({
  //       _id: reg._id,
  //       id: index+1,
  //       name: reg?.employee?.name,
  //       phoneNo: reg?.employee?.phoneNo,
  //       email:reg?.employee?.email,
  //       court: reg?.court?.name || "NA",
  //       transportation:reg?.transportation?"Requested":"Refused",
  //       transportationStatus: reg?.transportationStatus !== undefined
  //       ? reg.transportationStatus
  //         ? "Approved"
  //         : "Declined"
  //       : reg?.transportation
  //         ? "Requested"
  //         : "-",
  //       status:reg.registrationStatus === true ? "Approved" : reg.registrationStatus === false ? "Declined" : "Pending",
  //       courtData: reg?.event?.court,
  //       eventid:id,
  //       techManager:reg.event?.techManager||null,
  //       eventManager:reg.event?.eventManager||null

  //     }));
  //     setData(temp);
  //     setLoading(false);
  //   }
  //   setLoading(false);
  // };

  const getRegs = async () => {
    setLoading(true);
    const resp = await getregisterations(id);
    if (resp) {
      const temp = resp?.data?.registeredEmployees?.map((reg, index) => {
        let roleIndicator = "";
        if (reg.event?.techManager === reg.employee?.name)
          roleIndicator += "⚙️";
        if (reg.event?.eventManager === reg.employee?.name)
          roleIndicator += "👑";

        return {
          _id: reg._id,
          id: index + 1,
          name: reg.employee.name,
          shortLead: reg?.event?.lead,
          employeeID: reg.employee._id,
          address: reg.employee.address,
          RoleIndicator: roleIndicator ? roleIndicator : "",
          phoneNo: reg?.employee?.phoneNo,
          email: reg?.employee?.email,
          court: reg?.court?.name || "NA",
          transportation: reg?.transportation ? "Requested" : "Refused",
          transportationStatus:
            reg?.transportationStatus !== undefined
              ? reg.transportationStatus
                ? "Approved"
                : "Declined"
              : reg?.transportation
              ? "Requested"
              : "-",
          status:
            reg.registrationStatus === true
              ? "Approved"
              : reg.registrationStatus === false
              ? "Declined"
              : "Pending",
          courtData: reg?.event?.court,
          eventid: id,
          techManager: reg.event?.techManager || null,
          eventManager: reg.event?.eventManager || null,
        };
      });
      setData(temp);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEventDetails();
    getRegs();
  }, [id, update]);

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleEmployeeDownload = () => {
    if (data) {
      const filteredData = data
        .filter(
          (item) =>
            item.transportationStatus == "Approved" &&
            item.status === "Approved"
        ) // Filter rows where transportation is "Requested"
        .map(
          ({
            _id,
            RoleIndicator,
            courtData,
            eventid,
            employeeID,
            transportation,
            status,
            ...rest
          }) => rest
        ); // Exclude the some fields from each object
      if (filteredData.length > 0) {
        exportToExcel(filteredData, "employeeTransportation.xlsx");
      } else {
        alert("No approved transportation data available to export");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : eventDetails?.data?.eventFormat === "Local" ? (
        <LocalEventDetails />
      ) : (
        <div className={classes.container}>
          <ViewDetails
            link={to}
            eventDetails={eventDetails}
            remark={eventDetails?.data?.managerRemark}
            madefor={"Manager"}
          />
          <div className={classes.tblcontainer}>
            <p className={classes.paymentstatus}>
              {eventDetails?.data?.paymentStatus}
            </p>
            <div className="stickyTable">
              <CustomTable
                responsive={"viewEmployeeTabletTable"}
                columns={columns}
                data={data ? data : null}
                OptionsComponent={EmployeeOptions}
                status={true}
                update={update}
                setUpdate={setUpdate}
              />
              {(!eventDetails?.data?.eventEnded ||
                eventDetails.data.eventEnded === undefined) && (
                <button className={classes.newempbtn} onClick={handleOpen}>
                  Add Employee
                </button>
              )}
              {(!eventDetails?.data?.eventEnded ||
                eventDetails.data.eventEnded === undefined) &&
                data &&
                data.length > 0 && (
                  <button
                    className={classes.newempbtn}
                    onClick={handleEmployeeDownload}
                  >
                    Download
                  </button>
                )}
            </div>
          </div>
          <AddEmployeePopup
            open={dialogOpen}
            handleClose={handleClose}
            eventid={id}
            courtData={eventDetails?.data?.court}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      )}
    </>
  );
}

export default ViewEvent;
