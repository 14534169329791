import React, { useEffect, useState } from "react";
import classes from "./ManagerList.module.css";
import CustomTable from "../reusable/CustomTable";
import ViewOption from "./ViewOption";
import { getAllApprovedEvents, getEvents } from "../../utils/ApiFunctions";
import moment from "moment";
import NoData from "../reusable/NoData";
import Loader from "../reusable/Loader";
import MyPagination from "../reusable/MyPagination";
import {
  a11yProps,
  CustomTabPanel,
} from "../admin/events comps/LocalEventDetails/LocalEventDetails";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Event manager",
    accessor: "eventManager",
  },
  {
    Header: "Tech manager",
    accessor: "techManager",
  },
  {
    Header: "Participants",
    accessor: "participants",
  },
  {
    Header: "",
    accessor: "",
  },
];

const columnsAll = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Format",
    accessor: "format",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Event manager",
    accessor: "eventManager",
  },
  {
    Header: "Tech manager",
    accessor: "techManager",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "",
    accessor: "",
  },
];

const columnsLocal = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Groups",
    accessor: "groups",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "",
    accessor: "",
  },
];
function ManagerList(props) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [events, setEvents] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState([]);
  // const getAllEvents = async () => {
  //   const resp = await getEvents();
  //   console.log(resp);
  //   if (resp) {
  //     const temp = resp?.data?.events?.map((event) => {
  //       return {
  //         _id: event._id,
  //         id: event.eventid,
  //         name: event.eventName,
  //         date: moment(event.date).format("YYYY-MM-DD"),
  //         time: `${moment(event.fromTime).format("HH:mm")}-${moment(
  //           event.toTime
  //         ).format("HH:mm")}`,
  //         participants: event.noOfParticipants,
  //         eventManager: event.eventManager,
  //         techManager: event.techManager,
  //       };
  //     });
  //     console.log(temp);

  //     setEvents(temp);
  //     setTotalPages(resp.totalPages);
  //     setLoading(false);
  //   }
  //   setLoading(false);
  // };
  const getAllEvents = async () => {
    const resp = await getEvents();
    if (resp) {
      const now = moment(); // Current date and time
      const temp = resp?.data?.events
        ?.filter(
          (event) =>
            moment(event.date).isSameOrAfter(now, "day") &&
            event.status !== "Future event"
        )
        .map((event) => {
          return {
            _id: event._id,
            id: event.eventid,
            name: event.eventName,
            date: moment(event.date).format("YYYY-MM-DD"),
            time: `${moment(event.fromTime).format("HH:mm")}-${moment(
              event.toTime
            ).format("HH:mm")}`,
            participants: event.noOfParticipants,
            eventManager: event.eventManager,
            techManager: event.techManager,
            format: event.eventFormat,
            groups: event.groups.length,
          };
        })
        .sort((a, b) => {
          const dateComparison = moment(a.date).diff(moment(b.date));
          if (dateComparison === 0) {
            return moment(a.time.split("-")[0], "HH:mm").diff(
              moment(b.time.split("-")[0], "HH:mm")
            );
          }
          return dateComparison;
        });

      setEvents(temp);
      setTotalPages(resp.totalPages);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const filterEventsByFormat = (format) => {
    if (format === "All") {
      setFilteredEvents(events);
    } else {
      setFilteredEvents(events.filter((event) => event.format === format));
    }
  };

  const tabsData = [
    {
      id: 0,
      title: "All",
      columns: columnsAll,
    },
    {
      id: 1,
      title: "Public",
      columns: columns,
    },
    {
      id: 2,
      title: "Global",
      columns: columns,
    },
    {
      id: 3,
      title: "Local",
      columns: columnsLocal,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabsData[newValue];
    filterEventsByFormat(selectedTab.title);
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontFamily: "Poppins, sans-serif",
      // fontWeight: theme.typography.fontWeightRegular,
      // fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.75)",
      "&.Mui-selected": {
        color: "#DB545D",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#DB545D",
      },
    })
  );

  useEffect(() => {
    getAllEvents();
  }, []);
  useEffect(() => {
    filterEventsByFormat(tabsData[value].title);
  }, [events]);
  return (
    <>
      {loading && <Loader />}
      {!loading && events[0] === 0 && (
        <NoData
          title={"No Events assigned"}
          subtitle={"Wait for the events to be assigned"}
        />
      )}

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", margin: "0 2.4rem" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#DB545D",
              },
            }}
          >
            {tabsData.map((item) => {
              return (
                <StyledTab
                  label={item.title}
                  {...a11yProps(item.id)}
                  sx={{ fontSize: 16, fontWeight: 600 }}
                />
              );
            })}
          </Tabs>
        </Box>
        {tabsData.map((item) => {
          // let selectedColumns = columns;
          // if (item.id === 0) {
          //   selectedColumns = columnsAll;
          // } else if (item.id === 3) {
          //   selectedColumns = columnsLocal;
          // }

          return (
            <CustomTabPanel value={value} index={item.id} key={item.id}>
              {!loading && filteredEvents.length > 0 ? (
                <div className={`${classes.container}`}>
                  <CustomTable
                    responsive={"tableTabletManager"}
                    columns={item.columns}
                    data={filteredEvents}
                    OptionsComponent={ViewOption}
                  />
                  {/* <MyPagination page={page} setPage={setPage} totalPages={totalPages} /> */}
                </div>
              ) : (
                <NoData
                  title={"No Events Available"}
                  subtitle={"No data to display for this tab"}
                />
              )}
            </CustomTabPanel>
          );
        })}
      </Box>
    </>
  );
}

export default ManagerList;
