import React, { useEffect, useState } from "react";
import { DialogContentText } from "@mui/material";
import CustomCheckbox from "../reusable/CustomCheckbox";
import classes from "./RegisterPopup.module.css";
import DialogCard from "../reusable/DialogCard";
import { register, updateEvent } from "../../utils/ApiFunctions";
import Cookies from "js-cookie";
import MyFormControl from "../reusable/MyFormControl";

function RegisterPopup({ open, handleClose, id, update, setUpdate, courts }) {
  const employeeId = Cookies.get("userid");
  const employeeName = Cookies.get("empName");
  const [check, setCheck] = useState(false);
  const [court, setCourt] = useState("");
  const [form, setForm] = useState({
    employee: employeeId,
    name: employeeName,
    event: id,
    transportation: false,
    // court : {},
    // registrationStatus: false,
  });

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      event: id,
    }));
  }, [id]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      transportation: check,
    }));
  }, [check]);

  const handleRegister = async () => {
    const resp = await register({
      employee: employeeId,
      name: employeeName, // Set dynamically if needed
      // court: form.court, // Ensure this includes the full court object
      event: id,
      registrationStatus: null,
      transportation: check,
    });
    if (resp) {
      setUpdate(!update);
      handleClose();
    }
  };

  const handleCourtChange = (selectedCourt) => {
    // setCourt(selectedCourt);
    setForm((prev) => ({
      ...prev,
      // court: selectedCourt, // Update the court with the selected court details
      court: courts?.find((c) => c.name === selectedCourt),
    }));
  };

  return (
    <DialogCard
      open={open}
      handleClose={handleClose}
      title={"Register"}
      content={
        <>
          <DialogContentText id="alert-dialog-description">
            <p className={classes.maincontent}>
              Would you like to register for this event?
            </p>
            <p className={classes.subcontent}>
              Register and find your way of hospitality for the participants.
            </p>
          </DialogContentText>
          {/* <MyFormControl title={"Court"} data={courts?.map(c=>c?.name)} setValue={handleCourtChange} value={court}/> */}
          {/* <select
              id="court-select"
              value={form.court._id || ''}
              onChange={handleCourtChange}
            >
              <option value="" disabled>Select a court</option>
              {courts.map((court) => (
                <option key={court._id} value={court._id}>
                  {court.name}
                </option>
              ))}
            </select> */}
          <CustomCheckbox
            text={"Need transportation service"}
            setCheck={setCheck}
          />
        </>
      }
      btnOne={"Register"}
      btnTwo={"Cancel"}
      btnOneAction={handleRegister}
    />
  );
}

export default RegisterPopup;
