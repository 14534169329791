import React, { useEffect, useState } from "react";
import ViewDetails from "../reusable/ViewDetails";
import CustomTable from "../reusable/CustomTable";
import classes from "./ManagerViewDetails.module.css";
import ManagerOptions from "./ManagerOptions";
import AddEmployeePopup from "../admin/events comps/AddEmployeePopup";
import { Button } from "@mui/material";
import EndEventPopup from "./EndEventPopup";
import RemarkPopup from "./RemarkPopup";
import { useParams } from "react-router-dom";
import {
  getOneEvent,
  getregisterations,
  updateEvent,
} from "../../utils/ApiFunctions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LocalEventManager from "./LocalEventManager.jsx";
import Loader from "../reusable/Loader.jsx";

const events = [
  {
    id: 1,
    name: "Leslie Alexander",
    court: "007",
    phone: "9876543210",
    transportation: "Requested",
  },
  {
    id: 2,
    name: "Esther Howard",
    court: "Gold 2",
    phone: "894556789",
    transportation: "Requested",
  },
  {
    id: 3,
    name: "Floyd Miles",
    court: "Gold 1",
    phone: "7879894561",
    transportation: "Requested",
  },
  {
    id: 4,
    name: "Darlene Robertson",
    court: "Gold 1",
    phone: "7484889567",
    transportation: "Refused",
  },
  {
    id: 5,
    name: "Miles Morales",
    court: "Gold 2",
    phone: "8888459472",
    transportation: "Refused",
  },
  {
    id: 6,
    name: "Jenny Wilson",
    court: "007",
    phone: "7897895621",
    transportation: "Requested",
  },
];
const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Employees",
    accessor: "name",
  },
  {
    Header: "Court",
    accessor: "court",
  },
  {
    Header: "Phone no.",
    accessor: "phoneNo",
  },
  {
    Header: "Transportation",
    accessor: "transportationStatus",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "",
  },
];

function ManagerViewDetails(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenemployee, setDialogOpenemployee] = useState(false);
  const { id } = useParams();
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleOpenemployee = () => {
    setDialogOpenemployee(true);
  };

  const handleCloseemployee = () => {
    setDialogOpenemployee(false);
  };
  const [endEventState, setEndEventState] = useState(false);
  const handleEndEvent = async () => {
    const resp = await updateEvent(id, {
      eventEnded: true,
    });
    if (resp) {
      setEndEventState(!endEventState);
      handleClose();
    }
  };
  const [remarkOpen, setRemarkOpen] = useState(false);

  const handleRemarkOpen = () => {
    setRemarkOpen(true);
  };

  const handleRemarkClose = () => {
    setRemarkOpen(false);
  };
  const handleDownloadPdf = () => {
    const input = document.getElementById("pdfContainer");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("manager_view_details.pdf");
    });
  };
  const [eventDetails, setEventDetails] = useState("");

  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  const getEventDetails = async () => {
    setLoading(true);
    const resp = await getOneEvent(id);
    if (resp) {
      setEventDetails(resp.data);
    }
    setLoading(false);
  };
  const getRegs = async () => {
    setLoading(true);
    const resp = await getregisterations(id);
    if (resp) {
      const temp = resp?.data?.registeredEmployees
        ?.filter((reg) => reg.registrationStatus === true)
        .map((reg, index) => ({
          _id: reg._id,
          id: index + 1,
          eventTime: reg?.event?.fromTime,
          name: reg.employee.name,
          phoneNo: reg?.employee?.phoneNo,
          court: reg?.court?.name || "NA",
          transportation: reg?.transportation ? "Requested" : "Refused",
          transportationStatus:
            reg?.transportationStatus !== undefined
              ? reg.transportationStatus
                ? "Approved"
                : "Declined"
              : reg?.transportation
              ? "Requested"
              : "-",
          status:
            reg.registrationStatus === true
              ? "Approved"
              : reg.registrationStatus === false
              ? "Declined"
              : "Pending",
          courtData: reg.event.court,
          eventid: id,
          employeeID: reg.employee._id,
          techManager: reg.event?.techManager || null,
          eventManager: reg?.event?.eventManager || null,
          eventEnded: reg?.event?.eventEnded || null,
        }));
      setData(temp);
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    getEventDetails();
    getRegs();
  }, [endEventState, update]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : eventDetails?.data?.eventFormat == "Local" ? (
        <LocalEventManager />
      ) : (
        <div className={classes.container} id="pdfContainer">
          <ViewDetails
            link={"/manager"}
            eventDetails={eventDetails}
            remark={eventDetails.data?.adminRemark}
            madefor={"Admin"}
          />
          <div className={classes.tblcontainer}>
            <div className={classes.btncontainer}>
              {!eventDetails.data?.eventEnded && (
                <>
                  <Button className={classes.btn} onClick={handleOpen}>
                    End Event
                  </Button>
                  <Button className={classes.btn} onClick={handleDownloadPdf}>
                    Download
                  </Button>
                </>
              )}
              {eventDetails.data?.eventEnded && (
                <>
                  <div className={classes.btnended}>Event Ended</div>
                  {!eventDetails?.data?.managerRemark && (
                    <Button
                      className={classes.btnremark}
                      onClick={handleRemarkOpen}
                    >
                      Remark
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="stickyTable">
              <CustomTable
                columns={columns}
                data={data}
                OptionsComponent={ManagerOptions}
                status={update}
                setUpdate={setUpdate}
              />
              <Button className={classes.addbtn} onClick={handleOpenemployee}>
                Add Employee
              </Button>
            </div>
          </div>
          {!data.eventEnded && (
            <EndEventPopup
              open={dialogOpen}
              handleClose={handleClose}
              handleEndEvent={handleEndEvent}
              endEventState={endEventState}
              setEndEventState={setEndEventState}
            />
          )}
          <RemarkPopup
            open={remarkOpen}
            handleClose={handleRemarkClose}
            id={id}
            update={update}
            setUpdate={setUpdate}
          />
          <AddEmployeePopup
            open={dialogOpenemployee}
            handleClose={handleCloseemployee}
            eventid={id}
            courtData={eventDetails?.data?.court}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      )}
    </>
  );
}

export default ManagerViewDetails;
