import React, { useEffect, useState } from 'react';
import classes from './RegisteredEvent.module.css';
import CustomTable from '../reusable/CustomTable';
import moment from 'moment';
import { getregisteredEvents } from '../../utils/ApiFunctions';
import Cookies from "js-cookie";
import Loader from '../reusable/Loader';
import NoData from '../reusable/NoData';
import MyPagination from '../reusable/MyPagination';

const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Format",
    accessor: "format",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Transportation",
    accessor: "transportationStatus",
  },
  {
    Header: "",
    accessor: "action",
  },
];

function RegisteredEvent(props) {
  const [events, setEvents] = useState([]);  // Holds the event data
  const [page, setPage] = useState(1);  // Pagination state
  const [totalPages, setTotalPages] = useState("");  // Total pages for pagination
  const [loading, setLoading] = useState(true);  // Loading state to show loader
  const [update, setUpdate] = useState(true);  // Trigger for re-fetching data
  const employeeId = Cookies.get("userid");  // User ID from cookies

  // Function to fetch registered events
  const getOpenEvents = async () => {
    setLoading(true);  // Set loading to true while fetching
    try {
      const resp = await getregisteredEvents(page, employeeId);
      if (resp) {
        setTotalPages(resp.totalPages);  // Set the total pages from the response
        
        // Map the events data to fit the table structure
        const temp = resp.data?.events?.map((event, index) => ({
          _id: event._id,
          id: (page - 1) * 5 + index + 1,
          name: event.eventName,
          date: moment(event.date).format('YYYY-MM-DD'),
          time: `${moment(event.fromTime).format('HH:mm')}-${moment(event.toTime).format('HH:mm')}`,
          format: event.eventFormat,
          type: event.type,
          action: "cancelevent",
        }));

        // Map the registered employees' transportation status
        const temp2 = resp.data?.registeredEmployees?.map(e => ({
          eventid: e.event._id,
          transportationStatus: e.transportationStatus,
        }));

        // Merge the transportation status into the events
        const updatedTemp = temp?.map(event => {
          const matchingRegisteredEmployee = temp2.find(re => re.eventid === event._id);
          return matchingRegisteredEmployee
            ? { ...event, transportationStatus: matchingRegisteredEmployee.transportationStatus === undefined ? '-' : (matchingRegisteredEmployee.transportationStatus ? 'Approved' : 'Rejected') }
            : event;
        });

        setEvents(updatedTemp || []);  // Update the events state with the processed data
      } else {
        setEvents([]);  // If no data is returned, set events to an empty array
      }
    } catch (error) {
      console.error("Error fetching registered events", error);
      setEvents([]);  // On error, ensure events are empty
    } finally {
      setLoading(false);  // Set loading to false once the API call finishes
    }
  };

  // Fetch events on page or update change
  useEffect(() => {
    getOpenEvents();
  }, [update, page]);

  // Render the component
  return (
    <>
      {loading && <Loader />}  {/* Show Loader while loading is true */}
      
      {!loading && events.length === 0 && (
        <NoData
          title={"No Events Approved"}
          subtitle={"Wait for the Admin to approve the events"}
        />
      )}

      {!loading && events.length > 0 && (
        <div className={classes.container}>
          <CustomTable
            responsive={'tableTabletEmployeeEvents'}
            columns={columns}
            data={events}
            update={update}
            setUpdate={setUpdate}
            showRegisterCancelOption={false}
          />
          <MyPagination page={page} setPage={setPage} totalPages={totalPages} />
        </div>
      )}
    </>
  );
}

export default RegisteredEvent;
