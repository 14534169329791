import React from 'react';
import CustomDialog from '../reusable/CustomDialog';
import { cancel } from '../../utils/ApiFunctions';
import Cookies from "js-cookie";

function CancelPopup({ open, handleClose, regId, update, setUpdate,title}) {
  // const employeeId = empID ? empID : Cookies.get("userid");

  const handleCancel = async () => {

    const resp = await cancel(regId);
    if (resp) {
      setUpdate(!update);
    }
  };

  return (
    <>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title={title ? title : "Cancel"}
        maincontent={title ?  "Are you sure you want to delete an employee ?" : "Are you sure you want to cancel the registration ?"}
        subcontent={"You will no longer be part of this event."}
        btnOne={ title ? "Delete employee" : "Cancel Registration"}
        btnTwo={"Back"}
        btnOneClick={handleCancel}
      />

    </>
  );
}

export default CancelPopup;