import React, { useEffect, useRef, useState } from "react";
import techmanagericon from "../../../images/techmanagericon.svg";
import eventmanagericon from "../../../images/eventmanagericon.svg";
import classes from "./AssignasOptions.module.css";
import declineicon from "../../../images/declineicon.svg";
import { updateEvent } from "../../../utils/ApiFunctions";
import { updateRegistration } from "../../../utils/ApiFunctions";
import DeclinePopup from "./DeclinePopup";

function AssignasOptions({
  data,
  update,
  setUpdate,
  assignoptionsOpen,
  setOptionsOpen,
  setassignOptionsOpen,
}) {
  const boxRef = useRef(null);

  const handleBackdropClick = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setassignOptionsOpen(false);
    }
  };

  const techClickHandler = async () => {
    const resp = await updateEvent(data.eventid, {
      techManager: data.techManager === data.name ? null : data.name,
    });
    if (resp) {
      await updateRegistration(data._id, {
        isTechManager: data.techManager !== data.name,
      });
      setassignOptionsOpen(false);
      setUpdate(!update);
    }
  };
  const eventClickHandler = async () => {
    const resp = await updateEvent(data.eventid, {
      eventManager: data.eventManager === data.name ? null : data.name,
    });
    if (resp) {
      await updateRegistration(data._id, {
        isEventManager: data.eventManager !== data.name,
      });
      setassignOptionsOpen(false);
      setUpdate(!update);
    }
  };
  const [declineOpen, setDeclineOpen] = useState(false);
  const handleDeclineOpen = () => {
    setDeclineOpen(true);
  };
  const handleDeclineClose = () => {
    setDeclineOpen(false);
  };
  useEffect(() => {
    if (!declineOpen) {
      document.addEventListener("click", handleBackdropClick, true);
      return () => {
        document.removeEventListener("click", handleBackdropClick, true);
      };
    }
    if (declineOpen) {
      setassignOptionsOpen(false);
    }
  }, [declineOpen]);
  return (
    <>
      {assignoptionsOpen && (
        <div className={classes.boxcontainer} ref={boxRef}>
          <div className={classes.box}>
            <div className={classes.btngrp}>
              {(!data.techManager || data.techManager === data.name) &&
                (!data.eventManager || data.eventManager !== data.name) && (
                  <p
                    className={`${classes.btn} ${classes.btntwo}`}
                    onClick={techClickHandler}
                  >
                    <img src={techmanagericon} className={classes.icon} />
                    <p className={classes.two}>
                      {data.techManager === data.name
                        ? "Remove Tech Manager"
                        : "Mark as Tech Manager"}
                    </p>
                  </p>
                )}

              {(!data.eventManager || data.eventManager === data.name) &&
                (!data.techManager || data.techManager !== data.name) && (
                  <p className={classes.btn} onClick={eventClickHandler}>
                    <img src={eventmanagericon} className={classes.icon} />
                    <p className={classes.three}>
                      {data.eventManager === data.name
                        ? "Remove Event Manager"
                        : "Mark as Event Manager"}
                    </p>
                  </p>
                )}
              {/* <p className={classes.btn} onClick={handleDeclineOpen}>
                <img src={declineicon} className={classes.icon} />
                <p className={classes.three}>Decline</p>
              </p> */}
            </div>
          </div>
        </div>
      )}
      <DeclinePopup
        open={declineOpen}
        handleClose={handleDeclineClose}
        regid={data._id}
        data={data}
        update={update}
        setUpdate={setUpdate}
        setassignOptionsOpen={setassignOptionsOpen}
        setOptionsOpen={setOptionsOpen}
      />
    </>
  );
}
export default AssignasOptions;
